import React from 'react';

function Selections(props) {
  return (
    <>
    <div className="title-swiper mb-2">
      <p className={`slide-title`}>sub-title</p>
    </div>
    <div className="row recent-view mb-3">
      <div className="col-6 col-md-6 col-lg-4 col-xl-3">
        <div className={`grid-item`}>
          <button
            type="button"
            className="btn-like"
          >
            <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>
          </button>
          <a href="#" className={`grid-item-inner`}>
            <img src="/img/related-articles-01.jpg" alt=""/>
            <div className="grid-caption">
              <h4>Vestibulum gravida elementum</h4>
              <div className="grid-bottom">
                <p className="variant-name">Hello!</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    </>
  );
}

export default Selections;